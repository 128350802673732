<template>
  <div class="check">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="HTML5" name="1"></el-tab-pane>
      <el-tab-pane label="JAVA" name="2"></el-tab-pane>
      <el-tab-pane label="机器视觉" name="3"></el-tab-pane>
      <el-tab-pane label="嵌入式" name="4"></el-tab-pane>
      <el-tab-pane label="Java全栈+AIGC开发" name="5"></el-tab-pane>
    </el-tabs>
    <h3 class="totalScore">
      <span style="margin-right: 15px">已选分数:{{ totalScore }}</span>
      <el-button type="primary" plain :disabled="disable" @click="skipPdf"
        >导出</el-button
      >
    </h3>
    <el-tree
      :data="questions"
      node-key="id"
      :accordion="true"
      :expand-on-click-node="false"
      :render-content="renderContent"
      @check-change="selectAction"
      v-loading="loading"
    >
    </el-tree>
    <!-- 分页 -->
    <!-- <div class="bottom">
      <el-pagination
        small
        layout="prev, pager, next"
        :page-size="50"
        :total="count"
      >
      </el-pagination>
    </div> -->
    <el-dialog :title="timu.c_name" :visible.sync="dialogTableVisible">
      <div class="question_info">
        <!-- 题目的详情展示 -->
        <h3 class="name">题目:{{ timu.title }} (总分{{ timu.score }})</h3>
        <div class="daan">
          <div v-html="timu.answer"></div>
        </div>
        <div class="pf">评分标准:{{ timu.biaozhun }}</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "TKCheck",
  data: function () {
    return {
      datas: [],
      count: 0,
      activeName: "1",
      totalScore: 0,
      tms: [], //存储所有的选中题目
      dialogTableVisible: false,
      timu: {},
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    let res = await this.$datas.tkCheck;
    this.datas = res.data.data;
    this.count = res.data.count;
    this.loading = false;
  },
  computed: {
    disable() {
      return this.totalScore == 100 ? false : true;
    },
    questions() {
      //按照课程名称整理数据
      let dataByName = [];
      for (let i in this.datas) {
        let item = this.datas[i];
        //定义变量代表当前数据是否存在
        let index = -1;
        for (let j in dataByName) {
          item.c_name = item.c_name.replace(/[ ]/g, '');  
          if (dataByName[j].label == item.c_name) {
            index = j;
            break;
          }
        }
        if (index != -1) {
          //说明不存在
          let obj = dataByName[index];
          obj.children.push({ ...item, label: item.title });
          dataByName[index] = obj;
        } else {
          let obj = { label: item.c_name, children: [], bianhao: item.bianhao };
          obj.children.push({
            ...item,
            label: item.title,
          });
          dataByName.push(obj);
        }
      }
      dataByName = dataByName.sort((item1, item2) => {
        let index1 = item1.bianhao.split("-")[1];
        let index2 = item2.bianhao.split("-")[1];
        return index1 - index2;
      });
      //定义变量存储阶段名称
      let phases = [];
      let datas = [];
      for (let i in this.datas) {
        let item = this.datas[i];
        if (phases.indexOf(item.c_phase) == -1) {
          let index = Math.floor(item.bianhao.split("-")[0] / 100) - 1;
          phases.splice(index, 0, item.c_phase);
          datas.splice(index, 0, { label: item.c_phase.replace(/[Vv]\d+/, ""), children: [] });
        }
      }
      // 将阶段名字相同的数据进行数据合并
      var tempDatas = [...datas];
      datas = [];
      for(var i in tempDatas){
          var orExist = false;
          for(var j in datas){
             if(datas[j].label == tempDatas[i].label && i != j){
                datas[j].children = tempDatas[i].children.concat(datas[j].children);
                console.log(datas[j].label, datas[j].children, tempDatas[i].children);
                orExist = true;
             }
          }
          if(!orExist){
            datas.push(tempDatas[i]);
          }
      }
      //组合数据
      for (let i in datas) {
        for (let j in dataByName) {
          let item1 = dataByName[j];
          if (datas[i].label == item1.children[0].c_phase.replace(/[Vv]\d+/, "")) {
            datas[i].children.push(item1);
          }
        }
      }
      return datas;
    },
  },
  methods: {
    renderContent(h, { node, data }) {
      if (!data.children) {
        return (
          <span class="custom-tree-node">
            <span class="label">
              <el-checkbox
                on-change={(val) => {
                  this.selectAction(data, val);
                }}
              >
                {node.label}
              </el-checkbox>
            </span>
            <span class="score">分值:{data.score}分</span>
            <span class="watch_detail">
              <el-button
                size="mini"
                type="text"
                on-click={() => {
                  this.timu = data;
                  this.dialogTableVisible = true;
                  //   this.$router.push({ name: "QustionInfo", query: { data } })
                }}
              >
                查看详情
              </el-button>

              <el-button
                size="mini"
                type="text"
                on-click={() => this.deleteAction(data)}
              >
                删除
              </el-button>
            </span>
          </span>
        );
      } else {
        return (
          <span class="custom-tree-node">
            <span class="label">{node.label}</span>
          </span>
        );
      }
    },
    //删除考试题
    deleteAction(data) {
      this.$axios({
        url: this.$IP.IP2 + "/exam/one/" + data.id + "/",
        method: "delete",
      })
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            this.$alert("题目已删除", "提示", {
              confirmButtonText: "确定",
            });
            //清除数据源
            for (let index in this.datas) {
              if (this.datas[index].id == data.id) {
                //清除数据源
                this.datas.splice(index, 1);
                break;
              }
            }
          }
        })
        .catch((err) => console.log(err));
    },
    async handleClick(tab) {
      this.loading = true;
      this.activeName = tab.name;
      //开始网络请求
      this.$datas.meta = { xk: tab.label, page: 1 };
      let res = await this.$datas.tkCheck;
      this.datas = res.data.data;
      this.loading = false;
    },
    selectAction(row, state) {
      if (state) {
        //选中
        if (!row.children) {
          //完成题目的添加
          this.tms.push(row);
          //设置选中的分数
          this.totalScore += row.score;
        }
      } else {
        //取消选中
        if (!row.children) {
          //完成题目的去除
          for (let i in this.tms) {
            if (this.tms[i].id == row.id) {
              this.tms.splice(i, 1);
              break;
            }
          }
          //完成分数的递减
          this.totalScore -= row.score;
        }
      }
    },
    skipPdf() {
      //跳转到pdf
      this.$router.push({ name: "PDF", query: { datas: { tms: this.tms } } });
    },
  },
};
</script>
<style lang="less">
.check {
  .totalScore {
    display: flex;
    justify-content: flex-end;
    color: #666;
    align-items: center;
  }
}
.el-tree-node__content {
  height: 40px;
  width: 90%;
  display: flex;
  .custom-tree-node {
    width: 100%;
    justify-content: space-between;
  }
  .label {
    display: inline-block;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: -3px;
    font-size: 14.5px;
  }
  .score {
    font-size: 12px;
    color: #666;
    padding: 0 30px;
    box-sizing: border-box;
    display: inline-block;
  }
  .el-button--text span {
    margin-left: 30px;
    &:hover {
      color: orangered;
      text-decoration: underline;
    }
  }
}
.check {
  .bottom {
    display: flex;
    justify-content: flex-end;
  }
}
.question_info {
  .name {
    color: #555;
  }
  .daan {
    p {
      font-size: 14px !important;
      color: #aaff;
    }
    margin: 15px 7px;
    span {
      font-size: 16px !important;
      color: #aaff !important;
    }
  }
  .pf {
    color: orangered;
    font-size: 12px;
    margin: 0 7px;
  }
}
</style>
